import "tailwindcss/tailwind.css";

import { Trans } from "@lingui/macro";
import React, { useEffect, useState } from "react";

import { useAssessments } from "@/client/services/assessment/assessment";
import { AssessmentTypes } from "@/client/services/assessment/constants/main";
import { useCreateAssessment } from "@/client/services/assessment/create";

export const AssessmentsPage = () => {
  const [discTestId, setDiscTestId] = useState<string | null>(null);
  const [workValuesTestId, setWorkValuesTestId] = useState<string | null>(null);

  const { createAssessment, loading: creatingAssessment, error } = useCreateAssessment();
  const { assessments } = useAssessments(); // Fetch all assessments

  // Check if the assessments have been taken
  useEffect(() => {
    if (Array.isArray(assessments)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      const discAssessment = assessments.find((a) => a.instrumentId === AssessmentTypes.DISC);
      const workValuesAssessment = assessments.find(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        (a) => a.instrumentId === AssessmentTypes.WORK_VALUES,
      );

      setDiscTestId(discAssessment ? discAssessment.id : null);
      setWorkValuesTestId(workValuesAssessment ? workValuesAssessment.id : null);
    }
  }, [assessments]);

  const handleStartDiscTest = async () => {
    try {
      const newAssessment = await createAssessment(AssessmentTypes.DISC);
      setDiscTestId(newAssessment.id); // Save the assessment ID
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error("Error creating DISC assessment:", error);
    }
  };

  const handleStartWorkValuesTest = async () => {
    try {
      const newAssessment = await createAssessment(AssessmentTypes.WORK_VALUES);
      setWorkValuesTestId(newAssessment.id); // Save the assessment ID
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error("Error creating Work Values assessment:", error);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="mb-6 text-center text-3xl font-bold">
        <Trans>Assessments</Trans>
      </h1>
      <p className="mb-8 text-gray-600">
        <Trans>
          What, why, by who, what do you get …..you see your results in the first personality
          snapshot you create. How long you can retake, how to upgrade to full report, that should
          lock the test. You can retake the assessments at any time, but that replaces the previous
          results also in existing personality snapshots.
        </Trans>
      </p>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        {/* DISC Personality Test Section */}
        <div>
          <h2 className="mb-4 text-xl font-semibold">
            <Trans>DISC Personality Test</Trans>
          </h2>
          <div className="rounded-lg bg-white p-6 shadow-lg">
            {discTestId ? (
              <>
                <img
                  src="/assets/disc_cloud2.png"
                  className="mb-4 h-auto w-full"
                  alt="DISC result"
                />
                <button
                  className="w-full rounded bg-slate-300 px-4 py-2 text-white transition duration-300 hover:bg-slate-400"
                  onClick={handleStartDiscTest}
                >
                  <Trans>Retake DISC personality assessment test</Trans>
                </button>
              </>
            ) : (
              <>
                <img
                  src="/assets/disc_cloud2.png"
                  className="mb-4 h-auto w-full"
                  alt="Empty DISC test"
                />
                <button
                  className="w-full rounded bg-green-500 px-4 py-2 text-white transition duration-300 hover:bg-green-600"
                  disabled={creatingAssessment} // Disable the button while creating assessment
                  onClick={handleStartDiscTest}
                >
                  {creatingAssessment ? (
                    <Trans>Starting...</Trans>
                  ) : (
                    <Trans>Start DISC personality assessment test</Trans>
                  )}
                </button>
              </>
            )}
          </div>
        </div>

        {/* Work Values Test Section */}
        <div>
          <h2 className="mb-4 text-xl font-semibold">
            <Trans>Work Values Test</Trans>
          </h2>
          <div className="rounded-lg bg-white p-6 shadow-lg">
            {workValuesTestId ? (
              <>
                <img
                  src="/assets/work-values.jpg"
                  className="mb-4 h-auto w-full"
                  alt="Work Values result"
                />
                <button
                  className="w-full rounded bg-slate-300 px-4 py-2 text-white transition duration-300 hover:bg-slate-400"
                  onClick={handleStartWorkValuesTest}
                >
                  <Trans>Retake Work Values assessment test</Trans>
                </button>
              </>
            ) : (
              <>
                <img
                  src="/assets/work-values.jpg"
                  className="mb-4 h-auto w-full"
                  alt="Empty Work Values test"
                />
                <button
                  className="w-full rounded bg-green-500 px-4 py-2 text-white transition duration-300 hover:bg-green-600"
                  disabled={creatingAssessment} // Disable the button while creating assessment
                  onClick={handleStartWorkValuesTest}
                >
                  {creatingAssessment ? (
                    <Trans>Starting...</Trans>
                  ) : (
                    <Trans>Start Work Values assessment test</Trans>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {error && <p className="mt-4 text-red-500">{error.message}</p>} {/* Display error message */}
    </div>
  );
};
