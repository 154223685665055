import { AssessmentDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect } from "react";

import { ASSESSMENTS_KEY } from "@/client/constants/query-keys"; // Create a constant for assessments key
import { axios } from "@/client/libs/axios";
import usePageCheck from "@/client/pages/builder/hooks/check-page";
import { useResumeStore } from "@/client/stores/resume";

import { AssessmentTypes } from "./constants/main";

// Fetch all assessments from the API
export const fetchAssessments = async () => {
  const response = await axios.get<AssessmentDto[], AxiosResponse<AssessmentDto[]>>(
    `/assessment/active`,
  );
  return response.data;
};

// Custom hook to use assessments
export const useActiveAssessments = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const { isCoverLetterPage, isPersonalitySnapshotPage, isResumePage } = usePageCheck();
  const {
    error,
    isLoading,
    data: assessments,
  } = useQuery({
    queryKey: [ASSESSMENTS_KEY],
    queryFn: fetchAssessments,
    // retryOnMount: true,
  });

  // Find the assessments for DISC and Work Values
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const discAssessment = assessments?.find((a) => a.instrumentId === AssessmentTypes.DISC);
  const workValuesAssessment = assessments?.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (a) => a.instrumentId === AssessmentTypes.WORK_VALUES,
  );

  // Use useEffect to set values only after assessments are loaded
  useEffect(() => {
    if (isLoading) return;
    setValue("sections.summary.testDate", discAssessment?.updatedAt);
    setValue("sections.summary.discToken", discAssessment?.reportAccessCodeStandard1_svg);
    setValue(
      "sections.summary.workValueToken",
      workValuesAssessment?.reportAccessCodeStandard1_svg,
    );
    setValue("sections.summary.isPersonalitySnapshotPage", isPersonalitySnapshotPage);
    setValue("sections.summary.isCoverLetterPage", isCoverLetterPage);
    setValue("sections.summary.isResumePage", isResumePage);
  }, [isLoading, assessments, discAssessment, workValuesAssessment, setValue]);

  return { assessments, isLoading, error };
};
